import React from 'react';

interface IIconProps {
  className?: string;
  width?: number;
  height?: number;
}

const CashAdvanceIcon = (props: IIconProps) => {
  const { className, width = 24, height = 24 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M10 2.5L10 22.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M8 18C8 20.2091 9.64162 22 11.6667 22H15.8037C17.569 22 19 20.4389 19 18.5132C19 17.0123 18.1197 15.6799 16.8145 15.2053L10.1855 12.7947C8.88035 12.3201 8 10.9877 8 9.48683C8 7.56111 9.43102 6 11.1963 6H15.3333C17.3584 6 19 7.79086 19 10"
        stroke="#426EFF"
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M14 2.5L14 22.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.5C6 18.7091 7.79086 20.5 10 20.5H14.5132C16.4389 20.5 18 18.9389 18 17.0132C18 15.5123 17.0396 14.1799 15.6158 13.7053L8.3842 11.2947C6.96038 10.8201 6 9.48767 6 7.98683C6 6.06111 7.56111 4.5 9.48683 4.5H14C16.2091 4.5 18 6.29086 18 8.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(CashAdvanceIcon);
