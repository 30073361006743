import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const WillIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 20.498C6.825 20.486 5.647 20.39 4.879 19.621C4 18.743 4 17.328 4 14.5V10.5C4 7.672 4 6.257 4.879 5.379C5.757 4.5 7.172 4.5 10 4.5H18C20.828 4.5 22.243 4.5 23.121 5.379C24 6.257 24 7.672 24 10.5V14.5C24 17.328 24 18.743 23.121 19.621C22.391 20.351 21.292 20.475 19.32 20.496L18.5 20.498"
        fill="#426EFF"
        fill-opacity="0.3"
      />
      <path
        opacity="0.5"
        d="M7 17.998C4.825 17.986 3.647 17.89 2.879 17.121C2 16.243 2 14.828 2 12V8C2 5.172 2 3.757 2.879 2.879C3.757 2 5.172 2 8 2H16C18.828 2 20.243 2 21.121 2.879C22 3.757 22 5.172 22 8V12C22 14.828 22 16.243 21.121 17.121C20.391 17.851 19.292 17.975 17.32 17.996L16.5 17.998"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path d="M9 6H15M7 9.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
      <path
        d="M10.89 13.9451C11.1995 13.681 11.5931 13.5359 12 13.5359C12.4069 13.5359 12.8005 13.681 13.11 13.9451C13.383 14.1791 13.724 14.3201 14.083 14.3491C14.4882 14.3813 14.8687 14.5569 15.1562 14.8442C15.4438 15.1315 15.6195 15.5119 15.652 15.9171C15.68 16.2771 15.822 16.6171 16.055 16.8911C16.3186 17.2005 16.4634 17.5937 16.4634 18.0001C16.4634 18.4066 16.3186 18.7997 16.055 19.1091C15.8209 19.3829 15.6797 19.724 15.652 20.0831C15.6197 20.4887 15.4439 20.8694 15.1562 21.157C14.8684 21.4445 14.4876 21.6201 14.082 21.6521C13.7232 21.6801 13.3825 21.8212 13.109 22.0551C12.7996 22.3187 12.4065 22.4635 12 22.4635C11.5935 22.4635 11.2004 22.3187 10.891 22.0551C10.6174 21.8206 10.2763 21.6791 9.917 21.6511C9.51196 21.6187 9.13171 21.4431 8.84439 21.1557C8.55706 20.8684 8.38144 20.4882 8.349 20.0831C8.32097 19.7238 8.1795 19.3828 7.945 19.1091C7.6814 18.7997 7.53662 18.4066 7.53662 18.0001C7.53662 17.5937 7.6814 17.2005 7.945 16.8911C8.1795 16.6175 8.32097 16.2764 8.349 15.9171C8.38144 15.5121 8.55706 15.1318 8.84439 14.8445C9.13171 14.5572 9.51196 14.3816 9.917 14.3491C10.277 14.3201 10.616 14.1791 10.89 13.9451Z"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M10.5 18.2L11.357 19L13.5 17"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default React.memo(WillIcon);
