import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const DebtIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.96061 15.0091L6.62728 8.30322L3.29395 15.0091C3.29395 15.8984 3.64513 16.7512 4.27026 17.38C4.89538 18.0088 5.74322 18.362 6.62728 18.362C7.51133 18.362 8.35918 18.0088 8.9843 17.38C9.60942 16.7512 9.96061 15.8984 9.96061 15.0091Z"
        fill="#426EFF"
        fillOpacity="0.3"
      />
      <path
        d="M23.2939 15.0091L19.9606 8.30322L16.6273 15.0091C16.6273 15.8984 16.9785 16.7512 17.6036 17.38C18.2287 18.0088 19.0766 18.362 19.9606 18.362C20.8447 18.362 21.6925 18.0088 22.3176 17.38C22.9428 16.7512 23.2939 15.8984 23.2939 15.0091Z"
        fill="#426EFF"
        fillOpacity="0.3"
      />
      <path
        d="M8.66667 12.5589L5.33333 5.85303L2 12.5589C2 13.4482 2.35119 14.301 2.97631 14.9298C3.60143 15.5586 4.44928 15.9118 5.33333 15.9118C6.21739 15.9118 7.06524 15.5586 7.69036 14.9298C8.31548 14.301 8.66667 13.4482 8.66667 12.5589Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M22 12.5589L18.6667 5.85303L15.3333 12.5589C15.3333 13.4482 15.6845 14.301 16.3096 14.9298C16.9348 15.5586 17.7826 15.9118 18.6667 15.9118C19.5507 15.9118 20.3986 15.5586 21.0237 14.9298C21.6488 14.301 22 13.4482 22 12.5589Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M6.44449 20.5H5.44449V22.5H6.44449V20.5ZM17.5556 22.5H18.5556V20.5H17.5556V22.5ZM5.16803 4.8667C4.62335 4.95802 4.25582 5.4736 4.34714 6.01828C4.43845 6.56296 4.95403 6.93049 5.49871 6.83918L5.16803 4.8667ZM12 4.73529L12.1654 3.74906L12 3.72134L11.8347 3.74906L12 4.73529ZM18.5014 6.83918C19.0461 6.93049 19.5616 6.56296 19.6529 6.01828C19.7443 5.4736 19.3767 4.95802 18.832 4.8667L18.5014 6.83918ZM13 2.5V1.5H11V2.5H13ZM11 21.5V22.5H13V21.5H11ZM6.44449 22.5H17.5556V20.5H6.44449V22.5ZM5.49871 6.83918L12.1654 5.72153L11.8347 3.74906L5.16803 4.8667L5.49871 6.83918ZM11.8347 5.72153L18.5014 6.83918L18.832 4.8667L12.1654 3.74906L11.8347 5.72153ZM11 2.5V21.5H13V2.5H11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(DebtIcon);
