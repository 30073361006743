import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const LoansIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3252 13.6739L5.3252 16.8739C5.3252 19.1141 5.3252 20.2342 5.76117 21.0899C6.14466 21.8425 6.75659 22.4545 7.50923 22.838C8.36488 23.2739 9.48499 23.2739 11.7252 23.2739H18.9252C21.1654 23.2739 22.2855 23.2739 23.1412 22.838C23.8938 22.4545 24.5057 21.8425 24.8892 21.0899C25.3252 20.2342 25.3252 19.1141 25.3252 16.8739V13.6739C25.3252 11.4337 25.3252 10.3136 24.8892 9.45796C24.5057 8.70532 23.8938 8.09339 23.1412 7.7099C22.2855 7.27393 21.1654 7.27393 18.9252 7.27393L11.7252 7.27393C9.48499 7.27393 8.36488 7.27393 7.50923 7.7099C6.75659 8.09339 6.14466 8.70532 5.76117 9.45796C5.3252 10.3136 5.3252 11.4337 5.3252 13.6739Z"
        fill="#426EFF"
        fillOpacity="0.3"
      />
      <circle
        opacity="0.5"
        cx="12"
        cy="12.5005"
        r="2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M2.5 9.5V9.5C3.89782 9.5 4.59674 9.5 5.14805 9.27164C5.88313 8.96716 6.46716 8.38313 6.77164 7.64805C7 7.09674 7 6.39782 7 5V5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17 5V5C17 6.39782 17 7.09674 17.2284 7.64805C17.5328 8.38313 18.1169 8.96716 18.8519 9.27164C19.4033 9.5 20.1022 9.5 21.5 9.5V9.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17 20V20C17 18.6022 17 17.9033 17.2284 17.3519C17.5328 16.6169 18.1169 16.0328 18.8519 15.7284C19.4033 15.5 20.1022 15.5 21.5 15.5V15.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M7 20V20C7 18.6022 7 17.9033 6.77164 17.3519C6.46716 16.6169 5.88313 16.0328 5.14805 15.7284C4.59674 15.5 3.89782 15.5 2.5 15.5V15.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.9V14.1C2 16.3402 2 17.4603 2.43597 18.316C2.81947 19.0686 3.43139 19.6805 4.18404 20.064C5.03969 20.5 6.15979 20.5 8.4 20.5H15.6C17.8402 20.5 18.9603 20.5 19.816 20.064C20.5686 19.6805 21.1805 19.0686 21.564 18.316C22 17.4603 22 16.3402 22 14.1V10.9C22 8.65979 22 7.53969 21.564 6.68404C21.1805 5.93139 20.5686 5.31947 19.816 4.93597C18.9603 4.5 17.8402 4.5 15.6 4.5L8.4 4.5C6.15979 4.5 5.03969 4.5 4.18404 4.93597C3.43139 5.31947 2.81947 5.93139 2.43597 6.68404C2 7.53969 2 8.65979 2 10.9Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(LoansIcon);
