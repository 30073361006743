import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const EarnMoneyIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2939 16.6192C10.2939 18.4603 13.2784 19.9526 16.9606 19.9526C20.6428 19.9526 23.6273 18.4603 23.6273 16.6192C23.6273 14.7781 20.6428 13.2859 16.9606 13.2859C13.2784 13.2859 10.2939 14.7781 10.2939 16.6192Z"
        fill="#426EFF"
        fillOpacity="0.3"
      />
      <path
        d="M10.2939 16.6192V21.0637C10.2939 22.9037 13.2784 24.397 16.9606 24.397C20.6428 24.397 23.6273 22.9037 23.6273 21.0637V16.6192"
        fill="#426EFF"
        fillOpacity="0.3"
      />
      <path
        opacity="0.5"
        d="M2 5.83333C2 7.02444 3.27111 8.12444 5.33333 8.72C7.39556 9.31556 9.93778 9.31556 12 8.72C14.0622 8.12444 15.3333 7.02444 15.3333 5.83333C15.3333 4.64222 14.0622 3.54222 12 2.94667C9.93778 2.35111 7.39556 2.35111 5.33333 2.94667C3.27111 3.54222 2 4.64222 2 5.83333ZM2 5.83333V16.9444C2 17.9311 2.85778 18.5556 4.22222 19.1667M2 11.3889C2 12.3756 2.85778 13 4.22222 13.6111"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6665 14.7222C8.6665 16.5634 11.6509 18.0556 15.3332 18.0556C19.0154 18.0556 21.9998 16.5634 21.9998 14.7222M8.6665 14.7222C8.6665 12.8811 11.6509 11.3889 15.3332 11.3889C19.0154 11.3889 21.9998 12.8811 21.9998 14.7222M8.6665 14.7222V19.1667C8.6665 21.0067 11.6509 22.5 15.3332 22.5C19.0154 22.5 21.9998 21.0067 21.9998 19.1667V14.7222"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(EarnMoneyIcon);
