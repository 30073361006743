import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const InsuranceIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.92893 8.42893C5.05357 10.3043 4 12.8478 4 15.5H24C24 12.8478 22.9464 10.3043 21.0711 8.42893C19.1957 6.55357 16.6522 5.5 14 5.5C11.3478 5.5 8.8043 6.55357 6.92893 8.42893Z"
        fill="#426EFF"
        fillOpacity="0.3"
      />
      <path
        opacity="0.5"
        d="M18 19.5C18 20.2956 17.6839 21.0587 17.1213 21.6213C16.5587 22.1839 15.7956 22.5 15 22.5C14.2044 22.5 13.4413 22.1839 12.8787 21.6213C12.3161 21.0587 12 20.2956 12 19.5V12.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M4.92893 5.42893C3.05357 7.3043 2 9.84784 2 12.5H22C22 9.84784 20.9464 7.3043 19.0711 5.42893C17.1957 3.55357 14.6522 2.5 12 2.5C9.34784 2.5 6.8043 3.55357 4.92893 5.42893Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(InsuranceIcon);
