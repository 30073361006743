import React from 'react';

import { list } from '../HomePage/Heros/A/Hero';

import style from './ExploreNewHorizons.scss';

const ExploreNewHorizons = () => {
  return (
    <section className={style.exploreNewHorizons}>
      <div className="container">
        <h6>Explore New Horizons</h6>
        <div className={style.items}>
          {list.map((item) => {
            return (
              <a key={item.href + item.title} href={item.href} target={item.target}>
                {item.icon}
                <h4>{item.title}</h4>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ExploreNewHorizons;
