import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CoursesIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 38 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8707 1.74634C18.3476 1.65275 18.8382 1.65275 19.3151 1.74634C19.8555 1.8524 20.3567 2.11643 21.0815 2.49822C21.1131 2.5149 21.1452 2.53181 21.1778 2.54894L34.828 9.73325L34.8589 9.7495C35.2684 9.96505 35.6209 10.1505 35.8857 10.3224C36.15 10.494 36.4456 10.7239 36.6091 11.0761C36.8259 11.5433 36.8259 12.0823 36.6091 12.5495C36.4456 12.9018 36.15 13.1316 35.8857 13.3032C35.6209 13.4751 35.2684 13.6606 34.8587 13.8762L34.828 13.8924L21.1778 21.0767C21.1452 21.0938 21.1132 21.1107 21.0815 21.1274C20.3567 21.5092 19.8555 21.7732 19.3151 21.8793C18.8382 21.9729 18.3476 21.9729 17.8707 21.8793C17.3303 21.7732 16.8291 21.5092 16.1043 21.1274C16.0727 21.1107 16.0406 21.0938 16.008 21.0767L2.35781 13.8924L2.32697 13.8761C1.91737 13.6606 1.56489 13.4751 1.30008 13.3032C1.03578 13.1316 0.740166 12.9018 0.576692 12.5495C0.359853 12.0823 0.359853 11.5433 0.576692 11.0761C0.740167 10.7239 1.03578 10.494 1.30008 10.3224C1.56489 10.1505 1.91738 9.96503 2.32699 9.74947L2.35781 9.73325L16.008 2.54894C16.0406 2.53181 16.0726 2.5149 16.1043 2.49822C16.8291 2.11643 17.3303 1.8524 17.8707 1.74634ZM19.0262 3.21826C18.7401 3.16211 18.4457 3.16211 18.1596 3.21826C17.8618 3.2767 17.5652 3.42444 16.7066 3.87631L3.05643 11.0606C2.60728 11.297 2.3169 11.4507 2.11681 11.5806C1.99112 11.6622 1.94406 11.7083 1.93122 11.7218C1.90834 11.7803 1.90834 11.8453 1.93122 11.9038C1.94406 11.9173 1.99112 11.9634 2.1168 12.045C2.31689 12.1749 2.60728 12.3286 3.05643 12.565L16.7066 19.7493C17.5652 20.2012 17.8618 20.3489 18.1596 20.4073C18.4457 20.4635 18.7401 20.4635 19.0262 20.4073C19.324 20.3489 19.6206 20.2012 20.4792 19.7493L34.1294 12.565C34.5785 12.3286 34.8689 12.1749 35.069 12.045C35.1946 11.9635 35.2417 11.9173 35.2546 11.9038C35.2774 11.8453 35.2774 11.7803 35.2546 11.7218C35.2417 11.7083 35.1946 11.6622 35.069 11.5806C34.8689 11.4507 34.5785 11.297 34.1294 11.0606L20.4792 3.87631C19.6206 3.42444 19.324 3.2767 19.0262 3.21826Z"
        fill="white"
      />
      <path
        d="M28.5 21.6666L28.5 26.7561C28.5 27.3331 28.5 27.6216 28.4093 27.9124C28.3364 28.1462 28.1817 28.427 28.0229 28.6135C27.8255 28.8454 27.6191 28.9757 27.2065 29.2363C22.0756 32.4767 15.9244 32.4767 10.7935 29.2363C10.3809 28.9757 10.1745 28.8454 9.97711 28.6135C9.81834 28.427 9.66357 28.1462 9.59066 27.9124C9.5 27.6216 9.5 27.3331 9.5 26.7561V21.6666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(CoursesIcon);
