import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CreditCardsIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="7.08911" width="20" height="16" rx="4" fill="#426EFF" fillOpacity="0.3" />
      <path
        opacity="0.5"
        d="M2.5 9.5H21.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M5.99988 13.5H9.99988"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="4.5"
        width="20"
        height="16"
        rx="4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(CreditCardsIcon);
